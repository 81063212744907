import React, { useEffect, useState } from 'react';
import { User } from '../../Models/User';
import Auth from '../../Auth/Auth';
import ReactTable from '../../ReactTable';
import UserModal from './UserModal'
import { Column, ColumnStyle } from '../../Models/Column';
import { Badge, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Publisher } from '../../Models/Publisher';
import { Tag } from '../FormFields/TagInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Developer } from '../../Models/Developer';

interface Props {
    users: User[];
    publishers: Publisher[];
    developers: Developer[];
    auth: Auth;
}
  
function UserList(props: Props) {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchEnabled, setSearchEnabled] = useState<boolean>(false);

    const toggleSearch = () => setSearchEnabled(!searchEnabled);

    useEffect(() => {
        if (props.users === null || props.users === undefined) {
            setUsers([]);
            setLoading(true);
        } else {
            setUsers(props.users);
            setLoading(false);
        }
    }, [props.auth, props.users]);

    const updateUserList = (newUser: User) => {
        if (users === null || users === undefined) {
            return [];
        }

        const updateOrAddUser = (): User[] => {
            const updatedUsers = users.map(user =>
              user.id === newUser.id ? newUser : user
            );
            if (!users.some(user => user.id === newUser.id)) {
              updatedUsers.push(newUser);
            }
            return updatedUsers;
          };
        
        setUsers(updateOrAddUser());
      };

    const getUserColumnHeaders = ((isNew: boolean) : Column[] => {
        const columnStyle : ColumnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        };
    
        const columns : Column[] = [
            {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'Email',
                accessor: 'email',
                maxWidth: 200,
                minWidth: 80,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'GamerTag',
                accessor: 'gamerTag',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'Role',
                accessor: 'mcpAccessLevel',
                maxWidth: 200,
                minWidth: 40,
                width: 20,
                style: columnStyle,
            },
            {
                Header: 'Enable Beta',
                accessor: 'enableBetaFeatures',
                maxWidth: 200,
                width: 40,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.enableBetaFeatures ? 'Yes' : 'No'
                )
            },
            {
                Header: 'Publishers',
                accessor: 'publishers',
                maxWidth: 200,
                minWidth: 100,
                width: 100,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.publishers && <div className="tags-container">
                        {Object.keys(row.original.publishers).map((publisherId: string, index: any) => { return <Badge key={index} className="tag-badge pill">{publisherId}</Badge> }) }
                    </div>
                )
            },
            {
                Header: 'Developers',
                accessor: 'developers',
                maxWidth: 200,
                minWidth: 100,
                width: 100,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.developers && <div className="tags-container">
                        {Object.keys(row.original.developers).map((developerId: string, index: any) => { return <Badge key={index} className="tag-badge pill">{developerId}</Badge> }) }
                    </div>
                )
            },
            {
                Header: 'Approved',
                accessor: 'isApproved',
                maxWidth: 200,
                minWidth: 30,
                width: 20,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.isApproved ? 'Yes' : 'No'
                )
            },
            {
                Header: '',
                accessor: 'edit',
                maxWidth: 10,
                minWidth: 10,
                width: 10,
                style: columnStyle,
                Cell: (row: any) => (
                    <UserModal auth={props.auth} user={row.row.original as User} publishers={props.publishers} developers={props.developers} isNew={isNew} updateUserCallback={updateUserList}/>
                )
            }
        ];
        return columns;
    });

    const userFilter = (rows: any, globalFilterValue: any) => {
        const filterValues = globalFilterValue.split(',');
        const result = rows.filter((row: any) => {
            const match = filterValues.find((fv: string) => Object.values(row.values).find((value: any) => {
                if (value === null || value === undefined) {
                    return false;
                } else if (typeof value === "string") {
                    return value.toLowerCase().includes(fv.trim().toLowerCase())
                } else if (Array.isArray(value)) {
                    return value.find((array_value: string) => { array_value?.toLowerCase().includes(fv.trim().toLowerCase()) });
                } else if (typeof value === 'object' && value !== null) {
                    return Object.keys(value).find((key: string) => key.toLowerCase().includes(fv.trim().toLowerCase()));
                } else {
                    return false;
                }
            }));

            return match?.trim();
        });
        
        return result;
    };

    return (
        <div>
            <div className="list-buttons-container">
                <UserModal auth={props.auth} user={new User()} publishers={props.publishers} developers={props.developers} isNew={true} updateUserCallback={updateUserList}/>
            </div>
            <ReactTable 
                data={users}
                columns={getUserColumnHeaders(false)}
                loading={loading}
                defaultSorted={
                    [
                        {
                            id: "name",
                            desc: false
                        }
                    ]
                }
                defaultPageSize={20}
                customFilter={userFilter}
                className="-striped -highlight table-container"
            />
        </div>
    );
}

export default UserList;