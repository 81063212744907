import React, { useEffect, useReducer, useState } from 'react';
import Auth from './Auth/Auth';
import { Card, Image, Navbar, Nav, NavItem, NavDropdown, NavLink, Container, Carousel } from 'react-bootstrap';
import { mpLogoStyle, navBottom } from './Constants/styles'
import mpLogo from './Images/msf-logo_b85d0d89.png';

import { SPLASH_TEXT } from './Constants/splashtext';

import './App.css';
import { Route, Routes } from 'react-router-dom';
import ViewOffer from './Components/ViewOffer';
import ViewReport from './Components/ViewReport';
import ViewValidationReport from './Components/ViewValidationReport';
import Home from './Home/Home';
import CreateUpdate from './Components/CreateUpdate';
import CreateNewOffer from './Components/CreateNewOffer';
import ContentBrowser from './Components/ContentBrowser';
import MarketplaceProductBrowser from './Components/MarketplaceProductBrowser';
import ContentPackageBrowser from './Components/ContentPackageBrowser';
import PartnerBrowser from './Components/Partner/PartnerBrowser';
import UserBrowser from './Components/User/UserBrowser';
import Validator from './Validator/Validator';
import Documentation from './Documentation/Documentation';
import DeveloperBrowser from './Components/Developer/DeveloperBrowser';
import { GetBanner } from './Api/WebApi';

interface Props {
  auth: Auth;
}

function App (props: Props) {
  const [banner, setBanner] = useState<string | null>(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    const getBanner = async () => {
      const banner = await GetBanner(props.auth);
      
      setBanner(banner);
    }

    getBanner();
  }, [props.auth]);

  const login = async () => {
    await props.auth.login();
  }

  const logout = () => {
    props.auth.logout();
  }

  const handleSelect = async (eventKey: string | null) => {
    if (eventKey as any === "logout") {
      logout();
    }
    if (eventKey as any === "login") {
      await login();
    }
  }

  const { isAuthenticated, isSignedInWithMSA, isApprovedCreator } = props.auth;

  var isSignedMSA = isSignedInWithMSA();
  var isApproved = isApprovedCreator();

  var creatorUser = props.auth.getUserName();

  var randomSplash = Math.floor(Math.random() * SPLASH_TEXT.length);

  var splashText = SPLASH_TEXT[randomSplash];
  splashText = splashText.replace(/\s+/g, '\xa0\xa0\xa0');

  const isFirstParty = props.auth.isInternal();

  return (
    <div>
      {banner && <Carousel controls={false} indicators={false} style={{background: "yellow", padding: "10px", textAlign: "center"}}>
        {banner.split("\n").map((item, index) => {return <Carousel.Item key={index}>{item}</Carousel.Item>})}
      </Carousel>}
      <Navbar style={{ marginBottom: 0 }} className="topnavbar navbar-dark">
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className='md-auto' onSelect={async (eventKey) => await handleSelect(eventKey)}>
              {isAuthenticated() && (
                <NavItem>
                    <NavLink eventKey={"none"} href="/contentBrowser">
                      2020 Marketplace
                    </NavLink> 
                </NavItem>

              )}

              {isAuthenticated() && isFirstParty && (
                <NavItem>
                    <NavLink eventKey={"none"} href="/misContentBrowser">
                      2024 Marketplace
                    </NavLink> 
                </NavItem>

              )}

              {isAuthenticated() && isFirstParty && (
                <NavItem>
                    <NavLink eventKey={"none"} href="/cidsContentBrowser">
                      2024 Packages
                    </NavLink> 
                </NavItem>

              )}

              {isAuthenticated() && isFirstParty &&
                <NavItem>
                    <NavLink eventKey={"none"} href="/validator">
                      Validator
                    </NavLink>
                </NavItem>
              }

              {isAuthenticated() && isFirstParty &&
                <NavDropdown title="PartnerDb" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/partnerBrowser" style={{textAlign: "left"}}>Partners</NavDropdown.Item>
                  <NavDropdown.Item href="/developerBrowser" style={{textAlign: "left"}}>Developers</NavDropdown.Item>
                  <NavDropdown.Item href="/userBrowser" style={{textAlign: "left"}}>Users</NavDropdown.Item>
                </NavDropdown>
              }

              {isAuthenticated() &&
                <NavItem>
                    <NavLink eventKey={"none"} href="/documentation/topics" >
                      Documentation
                    </NavLink>
                </NavItem>
              }

              {isAuthenticated() && (props.auth.isInternal() || props.auth.getEnableBetaFeatures()) &&
                <NavItem>
                    <NavLink eventKey={"none"} href="/comingsoon/xboxpackagepreview" >
                      Coming Soon!
                    </NavLink>
                </NavItem>
              }
            </Nav>


            <Nav className="ms-auto" onSelect={handleSelect}>
              {(isSignedMSA || isApproved) &&
                <NavItem>
                    <NavLink eventKey={"none"} disabled>
                      Hello {creatorUser}!
                    </NavLink>
                </NavItem>

              }
              {(isSignedMSA || isApproved) &&

                <NavItem>
                    <NavLink eventKey={"logout"}>
                      Log Out
                    </NavLink>
                </NavItem>

              }
              {
                (!isSignedMSA && !isApproved) &&
                <NavItem>
                  <NavLink eventKey={"login"}>
                    Log In
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Card style={navBottom} >
        {<Image src={mpLogo} style={mpLogoStyle} thumbnail />}
      </Card>
      {
        props.auth.isInternal() && <div className="position">
          <div className="tilt splash">
            <div id="statussplash" className="pop">{splashText}</div>
          </div>
        </div>}
      <Routes>
        <Route path="/" element={<Home auth={props.auth} refreshApp={forceUpdate} />} />
        <Route path="home" element={<Home auth={props.auth} refreshApp={forceUpdate} />} />
        <Route path="viewOffer/:offerId" element={<ViewOffer auth={props.auth} />} />
        <Route path="viewReport/:reportId" element={<ViewReport auth={props.auth} />} />
        <Route path="viewValidationReport/:reportId" element={<ViewValidationReport auth={props.auth} game={"fs20"} />} />
        <Route path="createupdate/:offerId" element={<CreateUpdate auth={props.auth} />} />
        <Route path="createNewOffer/:offerType/:offerId?" element={<CreateNewOffer auth={props.auth} />} />
        <Route path="contentBrowser/:offerType?" element={<ContentBrowser auth={props.auth} />} />
        <Route path="misContentBrowser/:offerType?" element={<MarketplaceProductBrowser auth={props.auth} />} />
        <Route path="cidsContentBrowser/:offerType?" element={<ContentPackageBrowser auth={props.auth} />} />
        <Route path="partnerBrowser" element={<PartnerBrowser auth={props.auth} />} />
        <Route path="developerBrowser" element={<DeveloperBrowser auth={props.auth} />} />
        <Route path="userBrowser" element={<UserBrowser auth={props.auth} />} />
        <Route path="validator" element={<Validator auth={props.auth} />} />
        <Route path="documentation/:page" element={<Documentation prefix={"documentation"}/>} />
        <Route path="comingsoon/:page" element={<Documentation auth={props.auth} prefix={"comingsoon"}/>} />
      </Routes>
    </div >
  );
}

export default App;
