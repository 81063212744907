import React, { useEffect, useState } from 'react';
import { Publisher } from '../../Models/Publisher';
import Auth from '../../Auth/Auth';
import ReactTable from '../../ReactTable';
import PartnerModal from './PartnerModal'
import { Column, ColumnStyle } from '../../Models/Column';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface Props {
    partners: Publisher[] | null | undefined;
    auth: Auth;
}
  
function PartnerList(props: Props) {
    const [partners, setPartners] = useState<Publisher[] | null>();
    const [loading, setLoading] = useState<boolean>(true);
    const [searchEnabled, setSearchEnabled] = useState<boolean>(false);

    const toggleSearch = () => setSearchEnabled(!searchEnabled);

    useEffect(() => {
        if (props.partners === null || props.partners === undefined) {
            setPartners([]);
            setLoading(true);
        } else {
            setPartners(props.partners);
            setLoading(false);
        }
    }, [props.auth, props.partners]);

    const updatePartnerList = (newPartner: Publisher) => {
        if (partners === null || partners === undefined) {
            return [];
        }

        const updateOrAddPartner = (): Publisher[] => {
            const updatedPartners = partners.map(partner =>
              partner.id === newPartner.id ? newPartner : partner
            );
            if (!partners.some(partner => partner.id === newPartner.id)) {
              updatedPartners.push(newPartner);
            }
            return updatedPartners;
          };
        
        setPartners(updateOrAddPartner());
      };

    const getPartnerColumnData = ((): Publisher[] => {
        if (partners === null || partners === undefined) {
            return [];
        }
    
        const convertedPartners: Publisher[] = partners.map((partner) => {
            return {
              ...partner,
              isApproved: partner.isApproved.toString()
            };
          });
    
        return convertedPartners;
    });

    const getPartnerColumnHeaders = ((isNew: boolean) : Column[] => {
        const columnStyle : ColumnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        };
    
        const columns : Column[] = [
            {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
                sortable: true
            },
            {
                Header: 'Publisher Id',
                accessor: 'id',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
                sortable: true
            },
            {
                Header: 'Seller Id',
                accessor: 'sellerId',
                maxWidth: 200,
                minWidth: 20,
                width: 20,
                style: columnStyle,
                sortable: true
            },
            {
                Header: 'Partner Priority',
                accessor: 'partnerPriority',
                maxWidth: 200,
                minWidth: 40,
                width: 40,
                style: columnStyle,
                sortable: true
            },
            {
                Header: 'Release Owner',
                accessor: 'releaseOwner',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'Approved',
                accessor: 'isApproved',
                maxWidth: 200,
                minWidth: 20,
                width: 20,
                style: columnStyle
            },
            {
                Header: '',
                accessor: 'edit',
                maxWidth: 10,
                minWidth: 10,
                width: 10,
                style: columnStyle,
                filterable: false,
                sortable: false,
                Cell: ({row}: any) => (
                    <PartnerModal auth={props.auth} partner={row.original as Publisher} isNew={isNew} updatePartnerCallback={updatePartnerList}/>
                )
            }
        ];
        return columns;
    });

    return (
        <div>
            <div className="list-buttons-container">
                <PartnerModal auth={props.auth} partner={new Publisher()} isNew={true} updatePartnerCallback={updatePartnerList}/>
            </div>
            <ReactTable 
                data={getPartnerColumnData()}
                columns={getPartnerColumnHeaders(false)}
                loading={loading}
                defaultSorted={
                    [
                        {
                            id: "name",
                            desc: false
                        }
                    ]
                }
                defaultPageSize={20}
                className="-striped -highlight table-container"
                />
        </div>
    );
}

export default PartnerList;