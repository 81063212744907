import { MarketplaceItem, OfferField } from './MarketplaceItem';
import { MAX_UPLOAD_SIZE } from '../../Constants/enums';
import { GetMarketplacePackages } from '../../Api/RomaWebApi';
import { getLatestContentPackageDetailsResponse, PackageDetails, PlatformDetails, PublishingTarget } from './MarketplaceDetails';

const { v4: uuidv4 } = require('uuid');

export enum PackTypes {
    NONE = "Please Select a Content Type",
    AIRCRAFT = "Aircraft",
    AIRPORT = "Airport",
    MISSION = "Mission",
    LIVERY = "Livery",
    SCENERY = "Scenery",
    MISC = "Misc",
    INSTRUMENTS = "Instruments",
    BUNDLE = "Bundle",
    PACK = "Pack"
}

export class ContentPackage extends MarketplaceItem {
    packageName = new OfferField<string | null>(null, "Package Name", true);
    title = new OfferField<string | null>(null, "Title", true);
    submissionId = new OfferField<string | null>(uuidv4(), "Submission Id", false);
    packageContentType = new OfferField<string | null>(null, "Pack Type", true);
    publishingTarget = new OfferField<PublishingTarget>(PublishingTarget.PCAndXbox, "Publishing Target", false);
    draft = new OfferField<PlatformDetails | null>(null, "Draft", true);
    prerelease = new OfferField<PlatformDetails | null>(null, "Prerelease", true);
    release = new OfferField<PlatformDetails | null>(null, "Release", true);

    submissions:any[] = [];
    linkedSubmission: ContentPackage | null | undefined = null;

    constructor(data: any) {
        super(data);
        for (var prop in this) {
            if (data.hasOwnProperty(prop)) {
                if (this.hasOwnProperty(prop)) {
                    const offerField = this[prop] as OfferField<any>;

                    offerField.value = data[prop];

                    if (offerField.convertJson === true) {
                        try {
                            offerField.value = JSON.parse(offerField.value as string)
                        } catch(exception) { }
                    }

                } else {
                    console.log(`Can't find prop "${prop}" in ${typeof this}!`, this);
                    throw new Error(`Can't find prop "${prop}" in ${typeof this}!`);
                }
            }
        }

        if (this.auth) {
            GetMarketplacePackages(this.auth).then((result: any) => this.setSubmissions(result)).catch((error) => {
                console.log("error", error);
            })
        }
    }

    setSubmissions(result: { count: number, value: any }): void {
        if (result.count === 0) {
            console.log("No Offers Found");
            this.submissions = [];
        } else {
            this.submissions = result.value;
        }
    }

    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        super.setFieldFromName(propertyName, propertyValue);

        // if (propertyName === "packType") {
        //     //console.log("removing binary due to packType change");
        //     this.binaryFile.value = null;
        //     durableOffer.validationResult.value = null;
        // }

        return this;
    }

    static getColumnHeaders(isFirstParty = false) {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: 'Package Name',
                accessor: 'packageName', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'PC',
                columns: [
                    {
                        Header: 'Latest Version',
                        accessor: 'pcLatestVersion', // String-based value accessors!
                        style: columnStyle
                    },
                    {
                        Header: 'Status',
                        accessor: 'pcStatus', // String-based value accessors!
                        style: columnStyle
                    }
                ]
            },
            {
                Header: 'Xbox',
                columns: [
                    {
                        Header: 'Latest Version',
                        accessor: 'xboxLatestVersion', // String-based value accessors!
                        style: columnStyle
                    },
                    {
                        Header: 'Status',
                        accessor: 'xboxStatus', // String-based value accessors!
                        style: columnStyle
                    }
                ]
            }
        ]

        return columns;
    }

    convertToColumnData() {
        const reportEntry: PackageDetails<PlatformDetails> = this.export();
        const packageDetails = getLatestContentPackageDetailsResponse(reportEntry); // reportEntry.draft ?? reportEntry.prerelease ?? reportEntry.release;

        return (
            {
                packageName: reportEntry.packageName,
                pcLatestVersion: packageDetails.PC?.version ?? "N/A",
                pcStatus: packageDetails.PC?.releaseStatus ?? "N/A",
                xboxLatestVersion: packageDetails.Xbox?.version ?? "N/A",
                xboxStatus: packageDetails.Xbox?.releaseStatus ?? "N/A"
            }
        )
    }

    static get defaultListSorting() {
        return [
            {
                id: "developerId",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get defaultFirstPartyListSorting() {
        return [
            {
                id: "creatorName",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get MAX_FILE_SIZE() {
        return MAX_UPLOAD_SIZE;
    }
}