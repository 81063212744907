import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { center, formStyle } from '../../Constants/styles'
import { getPartnerV2 } from '../../Api/WebApi'
import Auth from '../../Auth/Auth';
import PartnerList from './PartnerList';
import { Publisher } from '../../Models/Publisher';

interface Props {
    auth: Auth;
}

function PartnerBrowser(props: Props) {
    const [partners, setPartners] = useState<Publisher[]>();

    useEffect(() => {
        document.title = "Partner Browser | Marketplace Content Portal";

        const fetchPartnerData = async () => {
            const result: Publisher[] = await getPartnerV2(props.auth);

            if (result.length === 0) {
                setPartners([]);
            } else {
                setPartners(result);
            }
        }

        fetchPartnerData();
    }, [props.auth]);

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card style={formStyle}>
                        <h1 style={center}>Partners</h1>
                        <PartnerList partners={partners} auth={props.auth} />
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default PartnerBrowser;