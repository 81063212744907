export class Developer {
    id: string = '';
    _etag?: string;
    created?: string;
    modified?: string;
    developerId: string = '';
    name: string = '';
    isApproved: boolean | string = false;
    publishers: string[] = [];
}
