import * as routes from '../Constants/routes'
import withRouter from '../withRouter';
import React, { Component } from 'react';
import AugerOfferFactory from '../Factories/AugerOfferFactory';

import { Button, Card } from 'react-bootstrap';
import { UpdateUploader } from './UpdateUploader';
import { GetSubmission, GetDefaultModel } from '../Api/WebApi'
import { center, formStyle } from '../Constants/styles'
import { AUGER_OFFER_TYPES, WORK_FLOW_TYPES } from '../Constants/enums';
import Auth from '../Auth/Auth'
import * as utils from '../Constants/utils';
import { DurableOfferModel } from '../Models/AugerOffers/DurableOfferModel';
import { NavigateFunction } from 'react-router';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import { UserAccessLevel } from '../Models/User';

interface Props {
    auth: Auth,
    navigate: NavigateFunction
    params: {
        offerType: string,
        offerId: string
    }
}

interface State {
    error: boolean,
    auth: Auth,
    augerOfferType: string,
    updateInfo: any,
    updateInfoLinked: any,
    submission: DurableOfferModel | null | undefined,
    submissions: DurableOfferModel[] | null
}

class CreateNewOffer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.initialState;
        this.processFormInfo = this.processFormInfo.bind(this);
        this.finishUpload = this.finishUpload.bind(this);

    }

    get initialState(): State {
        console.log("offerType",this.props.params.offerType);
        return {
            error: false,
            auth: this.props.auth,
            augerOfferType: this.props.params.offerType,
            updateInfo: null,
            updateInfoLinked: null,
            submission: null,
            submissions: null
        }
    }

    async componentDidMount() {
        let result = await GetDefaultModel(this.state.auth, this.props.params.offerType)
        .catch((error) => {
            console.log("error", error);
        });

        result.auth = this.props.auth;
        let submission = AugerOfferFactory.build(this.props.params.offerType, result) as DurableOfferModel;

        if (submission) {
            if (this.props.params.offerId) {
                submission.ingestionPlatforms.value = 'Xbox Only';
                submission.linkedSubmission = await this.getSubmission(this.state.auth, this.props.params.offerId);

                if (submission.linkedSubmission) {
                    submission.tempLinkedItem.value = submission.linkedSubmission.offerId.value;
                    submission.linkedSubmission.linkedSubmission = submission;
                }
            }

            this.setState(() => ({ submission: submission })); 
        }

        document.title = "Create Submission | Marketplace Content Portal";
    }

    async getSubmission(auth: Auth, offerId: string): Promise<DurableOfferModel | null | undefined> {
        let result = await GetSubmission(auth, offerId);

        result.auth = auth;
        result.binaryLocation = null;

        let submission = AugerOfferFactory.build(result.offerType, result) as DurableOfferModel;

        if (submission?.cardState.value === 'Closed') {
            submission.workflowType.value = WORK_FLOW_TYPES.OFFER;
            // default to showing full update.  user can change later if desired.
            // The Closed state indicates that an offer is being updated so allow the user to choose
            // any available workflowType.  once the first update is re-submitted and the workflow
            // has begun the user must complete the workflow before starting another.  this prevents
            // starting a workflow that does not include a binary and the editing later adding a binary 
            // but having bypassed all internal validation gates
        }

        return submission;
    }

    onSubmit() {
        let updateInfo = this.state.submission?.exportForSubmission();
        let updateInfoLinked: any = null;
        console.log("updateInfo", updateInfo);


        if (this.state.submission?.linkedSubmission) {
            updateInfoLinked = this.state.submission.linkedSubmission.exportForSubmission(false);
            console.log("updateInfoLinked:", updateInfoLinked);
            updateInfo.workItemPlatform = updateInfoLinked.workItemPlatform === 'PC' ? 'Xbox' : 'PC';
        } else {
            updateInfo.workItemPlatform = 'PC';

            if (this.state.submission?.ingestionPlatforms.value === 'PC & Xbox') {
                updateInfoLinked = { ...updateInfo };
                updateInfoLinked.relations = [];
                updateInfoLinked.workItemPlatform = 'Xbox';
            }
        }

        this.setState(() => ({
            updateInfo: updateInfo,
            updateInfoLinked: updateInfoLinked
        }));
    }

    onCancel() {
        const browser = routes.CONTENT_BROWSER_ROOT;
        console.log("Cancelled");
        this.props.navigate(browser);
    }

    finishUpload(uploadStatus: string) {
        
        console.log("uploadStatus", uploadStatus);
        this.props.navigate("/contentBrowser/" + this.props.params.offerType);

    }

    processFormInfo<T extends MarketplaceItem>(offer: T | undefined, propertyName: keyof T, propertyValue?: any) { 
        if (propertyValue == null) {
            return;
        }
    
        const {
            submission
        } = this.state;

        if (submission) {
            if (propertyName as string === 'ingestionPlatforms') {
                const propertyValueAsString = propertyValue as string;

                propertyValue = (propertyValueAsString.toLowerCase().includes('pc') && propertyValueAsString.toLowerCase().includes('xbox') ? 'PC & Xbox' : propertyValueAsString.includes('PC') ? 'PC Only' : 'Xbox Only');
            }

            var setSubmission = submission.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);

            this.setState(() => ({ submission: setSubmission}));
        }
    }

    render() {
        const {
            error,
            submission,
            auth,
            updateInfo,
            updateInfoLinked
        } = this.state;

        if (submission != null) {
            submission.offerId.value = null; // signal the WebAPI that this is a Create vs. an Update
            let enablePC = this.props.params.offerId ? false : true;
            var submissionFormFields = submission.getSubmissionFormFields(this.processFormInfo, true, enablePC, enablePC, !enablePC);
            var validOffer = submission.validateOffer(enablePC, enablePC, !enablePC).passed;

            return (
                <div className="container create-new">
                    <div className="row">
                        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Card style={formStyle}>
                                {error && <h3 style={center}> Error: {error} </h3>}
                                <h1 style={center}>New Product Submission</h1>
                                <div style={center}><em>Note: These fields are automatically extracted from the package to be submitted. If any fields are incorrect, please update the package with the correct values.</em></div>
                                {
                                    updateInfo &&
                                    <UpdateUploader auth={auth} updateInfo={updateInfo} updateInfoLinked={updateInfoLinked} callbackFromParent={this.finishUpload} />
                                }
                                {submissionFormFields}
                                <Button style={{ marginTop: 20 }} variant="primary"
                                    onClick={event => this.onSubmit()}
                                    title={validOffer || !submission.validationResult.value ? "" : "Please correct any errors indicated in red above before submitting."}
                                    disabled={!validOffer}>{"SUBMIT"}</Button>
                                <Button style={{ marginTop: 20 }} variant="default"
                                    onClick={event => this.onCancel()}>{"CANCEL"}</Button>
                            </Card>
                        </main>
                    </div>
                </div>
            );
        }
        else{
            return (
                <div className="container create-new">
                    <div className="row">
                        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Card style={formStyle}>
                                {error && <h3 style={center}> Error: {error} </h3>}
                                <h1 style={center}>New Product Submission</h1>
                            </Card>
                        </main>
                    </div>
                </div>
            );
        }

        
  
    }
}


export default withRouter(CreateNewOffer);